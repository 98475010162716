.login-container{
    margin-top: 10%;
    /* margin-left: 5%; */
    margin-bottom: 2em;
    /* width: 800px; */
    /* min-width: 400px; */
}

.login-form {
    border-radius: 5px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 1em;
    margin-right: 1em;
    margin-left: 1em;
    padding: 5%;
    min-width: 300px;
    max-width: 500px;
}

.login-form-1{
 
}
.login-form-1 h3{
    text-align: center;
    color: #333;
}
.login-form-2{
    background: #0062cc;
}
.login-form-2 h3{
    text-align: center;
    color: #fff;
}
.login-container form{
    padding: 10%;
}
.btnSubmit
{
    width: 50%;
    border-radius: 1rem;
    padding: 1.5%;
    border: none;
    cursor: pointer;
}
.login-form-1 .btnSubmit{
    color: #fff;
    background-color: #0062cc;
}
.login-form-2 .btnSubmit{
    background-color: #fff;
    color: #0062cc;
}
.login-form-2 .ForgetPwd{
    color: #fff;
    font-weight: 600;
    text-decoration: none;
}
.login-form-1 .ForgetPwd{
    color: #0062cc;
    font-weight: 600;
    text-decoration: none;
}